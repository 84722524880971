import { ChangeEvent, FC, FocusEvent, useRef } from 'react';

import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { FieldError as FieldErrorType } from 'react-hook-form';

import { Button } from '../components/Button';
import { FieldError } from '../components/FieldError';
import { Icon } from '../components/Icon';
import { Text } from '../components/Text';

const IS_IOS =
	typeof navigator !== 'undefined' ? navigator.userAgent.match(/iPhone|iPad|iPod/i) : false;

interface InputProps {
	hasError: boolean;
	rafFormStyle?: boolean;
	small?: boolean;
}

const Container = styled('div')`
	position: relative;
	min-width: 100px;
	${({ theme }) => theme.breakpoints.down('md')} {
		display: flex;
		align-items: center;
	}
`;

const Input = styled('input', {
	shouldForwardProp: (prop) => prop !== 'hasError' && prop !== 'rafFormStyle' && prop !== 'small',
})<InputProps>`
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	appearance: textfield;
	width: 100%;
	color: ${({ theme, rafFormStyle }) =>
		rafFormStyle ? theme.colors.primary : theme.colors.grayDarker};
	font-size: ${({ rafFormStyle }) => (rafFormStyle ? '28px' : '16px')};
	line-height: 1.56;
	height: 38px;
	font-weight: ${({ rafFormStyle }) => (rafFormStyle ? 300 : 500)};
	border-radius: 0;
	border: none;
	border-bottom: ${({ theme, hasError, rafFormStyle }) =>
		rafFormStyle
			? 'none'
			: hasError
				? `1px solid ${theme.colors.danger}`
				: `1px solid ${theme.colors.gray}`};
	padding: ${({ rafFormStyle, small }) => (rafFormStyle ? 0 : small ? '6px 25px' : '6px 47px')};
	outline: none;
	box-sizing: border-box;
	text-align: center;
	transition: border-bottom ${({ theme }) => theme.transition.baseTransition};
	background: transparent;

	::-webkit-inner-spin-button,
	::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}

	:focus {
		border-bottom: 1px solid
			${({ theme, hasError }) => (!hasError ? theme.colors.primary : theme.colors.danger)};
	}

	::placeholder {
		color: ${({ theme }) => theme.colors.gray};
	}
`;

const StyledIcon = styled(Icon)`
	width: 25px;
	height: 25px;
`;

const ButtonsPlusMinus = styled(Button, {
	shouldForwardProp: (prop) => prop !== 'rafFormStyle' && prop !== 'small',
})<{ rafFormStyle?: boolean }>`
	position: absolute;
	top: ${({ rafFormStyle }) => (rafFormStyle ? '2px' : '-2px')};

	${({ theme }) => theme.breakpoints.up('md')} {
		top: ${({ rafFormStyle }) => (rafFormStyle ? '10px' : '5px')};
	}
`;

const ButtonMinus = styled(ButtonsPlusMinus, {
	shouldForwardProp: (prop) => prop !== 'rafFormStyle' && prop !== 'small',
})<{ rafFormStyle?: boolean }>`
	left: ${({ rafFormStyle }) => (rafFormStyle ? '-18px' : '0')};

	${({ theme }) => theme.breakpoints.up('md')} {
		left: ${({ rafFormStyle, small }) => (rafFormStyle ? '-3px' : small ? '0' : '10px')};
	}
`;

const ButtonPlus = styled(ButtonsPlusMinus, {
	shouldForwardProp: (prop) => prop !== 'rafFormStyle' && prop !== 'small',
})<{ rafFormStyle?: boolean }>`
	right: ${({ rafFormStyle }) => (rafFormStyle ? '1px' : '10px')};
	padding-right: 2px !important;

	${({ theme }) => theme.breakpoints.up('md')} {
		right: ${({ rafFormStyle, small }) => (rafFormStyle ? '-3px' : small ? '0' : '10px')};
	}
`;

const ErrorMessage = styled(Text)<{ marginTop?: number }>`
	display: block;
	margin: ${({ marginTop }) => `${marginTop ?? 6}px 0 0 10px`};
`;

const BottomLine = styled('div')<{ height?: number; hasError?: boolean }>`
	margin-top: 29px;
	height: ${({ height }) => `${height ?? 1}px`};
	width: 92px;
	background-color: ${({ theme, hasError }) =>
		hasError ? theme.colors.danger : theme.colors.gray};
	display: block;

	${({ theme }) => theme.breakpoints.down('md')} {
		position: absolute;
		bottom: -26px;
		width: 100%;
	}
`;

interface NumberInputProps {
	placeholder?: string;
	step?: number;
	max?: number;
	min?: number;
	forceIsErrorShown?: boolean;
	rafFormStyle?: boolean;
	height?: number;
	marginTopError?: number;
	value: number;
	error?: FieldErrorType;
	onChange: (value: any) => void;
	name: string;
	small?: boolean;
}

export const NumberInput: FC<NumberInputProps> = ({
	placeholder,
	step = 1,
	max = 9999,
	min = 0,
	rafFormStyle,
	height,
	marginTopError,
	value,
	error,
	onChange,
	name,
	small,
}) => {
	const hasError = !!error;
	const containerRef = useRef<HTMLDivElement | undefined>(null);
	const finalValue: number | undefined = value?.toString() === '' ? undefined : (value as number);

	const trimToBounds = (localValue?: number) => {
		const valueInt = localValue ?? finalValue;

		if (valueInt > max) {
			return max;
		}

		if (valueInt < min) {
			return min;
		}

		return valueInt.toString();
	};

	const isOutOfBounds = (localValue?: number) => {
		const valueInt = localValue ?? finalValue;

		return valueInt > max || valueInt < min;
	};

	const handleButtonMinusClick = () => {
		if (isNaN(finalValue)) {
			onChange(min);
		}

		if (isOutOfBounds()) {
			onChange(trimToBounds());
		}

		if (finalValue >= min + step) {
			onChange(finalValue - step);
		}
	};

	const handleButtonPlusClick = () => {
		if (isNaN(finalValue)) {
			onChange(min);
		}

		if (isOutOfBounds()) {
			onChange(trimToBounds());
		}

		if (finalValue <= max - step) {
			onChange(finalValue + step);
		}
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.value === '') {
			onChange(undefined);
			return;
		}

		if (Number.isNaN(parseInt(event.target.value, 10))) {
			onChange(undefined);
			return;
		}

		if (isOutOfBounds(parseInt(event.target.value, 10))) {
			onChange(finalValue);
			return;
		}

		onChange(parseInt(event.target.value, 10));
	};

	const handleBlur = (event: FocusEvent<HTMLElement>) => {
		if (containerRef.current.contains(event.relatedTarget as HTMLElement)) {
			return;
		}
	};

	return (
		<Container ref={containerRef} data-testid={`number-input-container-${name}`}>
			<ButtonMinus
				iconOnly
				onClick={handleButtonMinusClick}
				rafFormStyle={rafFormStyle}
				data-testid="button-minus"
				small={small}
				disabled={value === min}
			>
				{value === min ? (
					<Tooltip title="Minimum value has been reached">
						<StyledIcon type={rafFormStyle ? 'minus-circle-blue-small' : 'minus-circle-blue'} />
					</Tooltip>
				) : (
					<StyledIcon type={rafFormStyle ? 'minus-circle-blue-small' : 'minus-circle-blue'} />
				)}
			</ButtonMinus>

			<Input
				onBlur={handleBlur}
				onChange={handleChange}
				hasError={hasError}
				placeholder={placeholder}
				type="number"
				min={min}
				max={max}
				step={step}
				pattern={IS_IOS ? `[0-9]*` : ''}
				data-testid={`number-input-${name}`}
				rafFormStyle={rafFormStyle}
				value={value}
				small={small}
			/>

			<ButtonPlus
				iconOnly
				onClick={handleButtonPlusClick}
				rafFormStyle={rafFormStyle}
				data-testid="button-plus"
				small={small}
				disabled={value === max}
			>
				{value === max ? (
					<Tooltip title="Maximum value has been reached">
						<StyledIcon type={rafFormStyle ? 'plus-circle-blue-small' : 'plus-circle-blue'} />
					</Tooltip>
				) : (
					<StyledIcon type={rafFormStyle ? 'plus-circle-blue-small' : 'plus-circle-blue'} />
				)}
			</ButtonPlus>

			{rafFormStyle && <BottomLine height={height} hasError={hasError} />}
			{hasError && (
				<ErrorMessage marginTop={marginTopError} danger subtext data-testid="error-message">
					<FieldError error={error.message} />
				</ErrorMessage>
			)}
		</Container>
	);
};
