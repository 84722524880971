import { FC } from 'react';

import styled from '@emotion/styled';

import { Icon, IconKeys } from '../../components/Icon';
import { H3 } from '../../components/Text';

interface HeadingProps {
	selectedOption?: any;
	heading: string;
	icon: IconKeys;
}

const StyledLargerTextIcon = styled(Icon)`
	height: 100%;
	width: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
`;

const HeadingContainer = styled('div')`
	display: flex;
	flex-direction: row;
	position: absolute;
	top: -32px;
	left: -29px;
	justify-content: flex-start;
	align-items: center;
`;

const StyledH3 = styled(H3)`
	font-weight: 300;
`;

export const Heading: FC<HeadingProps> = ({ selectedOption, heading, icon }) => {
	const airport = selectedOption?.value;
	return (
		<HeadingContainer>
			<StyledLargerTextIcon type={icon} />
			{airport?.airportCode ? (
				<StyledH3 primary>{airport?.airportCode}</StyledH3>
			) : (
				<StyledH3 grayDark>{heading}</StyledH3>
			)}
		</HeadingContainer>
	);
};
