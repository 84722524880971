import { Dayjs } from 'dayjs';

import { formatDate, toIsoString } from '@fllite-fe/shared/src/utils/datetime';
import dayjs from '@fllite-fe/shared/src/utils/extendedDayjs';

import { MeridiemsType } from '.';

export const dayjsToDate = (localDayjs: Dayjs) => {
	return new Date(localDayjs.year(), localDayjs.month(), localDayjs.date());
};

export const parseValue = (date: string, localTimezone: string) => {
	if (!date || !localTimezone) {
		const meridiem: MeridiemsType = 'AM';
		return {
			day: undefined,
			hours: 0,
			minutes: 0,
			meridiem: meridiem,
		};
	}
	const dateInDayjs = dayjs(date).tz(localTimezone);
	const meridiem: MeridiemsType = dateInDayjs?.hour() >= 12 ? 'PM' : 'AM';
	const day = dayjsToDate(dateInDayjs);

	const hours = ((dateInDayjs?.hour() + 11) % 12) + 1;
	const minutes = dateInDayjs?.minute() - (dateInDayjs?.minute() % 15);

	return {
		day,
		hours,
		minutes,
		meridiem,
	};
};

export const getDate = (date) => (date ? new Date(date) : undefined);
export const getDateString = (date) => (date ? toIsoString(date) : undefined);

export const dateToDayjs = (date: Date, localTimezone: string) => {
	let monthString = `${date?.getMonth() + 1}`;
	let dayString = `${date?.getDate()}`;

	if (monthString.length < 2) {
		monthString = `0${monthString}`;
	}

	if (dayString.length < 2) {
		dayString = `0${dayString}`;
	}

	return dayjs.tz(`${date?.getFullYear()}-${monthString}-${dayString} 00:00`, localTimezone);
};

export const hours12to24 = (hour: number, meridiemType: MeridiemsType) => {
	const isPM = meridiemType === 'PM';
	return hour === 12 ? (isPM ? 12 : 0) : isPM ? hour + 12 : hour;
};

export const getMobileTimeText = (date, hour, min) => {
	return (
		(date ? formatDate(date, 'MMM DD, dddd') + ', ' : '--, ') +
		(((hour + 11) % 12) + 1).toString().padStart(2, '0') +
		':' +
		min.toString().padStart(2, '0') +
		' ' +
		(hour >= 12 ? 'PM' : 'AM')
	);
};
