import { useMemo, useState } from 'react';

import { CloseRounded } from '@mui/icons-material';
import {
	Autocomplete,
	AutocompleteProps,
	Box,
	Checkbox,
	Chip,
	TextField,
	TextFieldProps,
} from '@mui/material';
import { Controller, ControllerProps, Path } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { PulseLoader } from 'react-spinners';

import { IconKeys } from '../../components/Icon';
import { geocodingGetAddressInfo, searchGetAddressInfo } from '../../utils/mapbox';
import { AirportsMenuOption } from './AirportsMenuOption';
import { Heading } from './Heading';
import { MapBoxMenuOption } from './MapBoxMenuOption';

export enum AutocompleteType {
	Airport = 'airport',
	MapBoxGeocoding = 'mapboxGeocoding',
	MapBoxSearch = 'mapboxSearch',
	Checkboxes = 'checkboxes',
}

export type AutocompleteElementProps<
	F extends FieldValues,
	T,
	M extends boolean | undefined,
	D extends boolean | undefined,
> = {
	name: Path<F>;
	options: T[];
	loading?: boolean;
	multiple?: M;
	matchId?: boolean;
	rules?: ControllerProps['rules'];
	required?: boolean;
	label?: TextFieldProps['label'];
	autocompleteProps?: Omit<
		AutocompleteProps<T, M, D, any>,
		'name' | 'options' | 'loading' | 'renderInput'
	>;
	textFieldProps?: Omit<TextFieldProps, 'name' | 'required' | 'label'>;
	withHeading?: string;
	icon?: IconKeys;
	native?: boolean;
	isRaf?: boolean;
	isPremade?: boolean;
	isPersonalization?: boolean;
	limitTags?: number;
	max?: number;
	customOnInputChange: (val: string) => void;
	type?: AutocompleteType;
};

type AutoDefault = {
	id: string | number; // must keep id in case of keepObject
	label: string;
};

export default function AutocompleteElement<TFieldValues extends FieldValues>({
	textFieldProps,
	autocompleteProps,
	name,
	options,
	loading,
	rules,
	required,
	multiple,
	matchId,
	label,
	withHeading,
	icon,
	native = false,
	isRaf,
	isPremade,
	isPersonalization,
	limitTags = -1,
	max,
	customOnInputChange,
	type = AutocompleteType.Checkboxes,
}: AutocompleteElementProps<
	TFieldValues,
	AutoDefault | string | any,
	boolean | undefined,
	boolean | undefined
>) {
	const validationRules: ControllerProps['rules'] = {
		...rules,
		...(required && {
			required: rules?.required || 'This field is required',
		}),
	};
	const [isFocused, setIsFocused] = useState(false);
	const [inputValue, setInputValue] = useState('');

	const getCurrentValue = (value: any) => {
		let currentValue = multiple ? value || [] : value || null;
		if (matchId) {
			currentValue = multiple
				? (value || []).map((i: any) => options.find((j) => (j.id || j) === i))
				: options.find((i) => (i.id || i) === value) || null;
		}
		return currentValue;
	};

	const handleOnChange = (event, val, reason, details, onChange) => {
		let changedVal = val;
		if (matchId) {
			changedVal = Array.isArray(val) ? val.map((i: any) => i?.id || i) : val?.id || val;
		}
		onChange(changedVal);
		if (autocompleteProps?.onChange) {
			autocompleteProps.onChange(event, val, reason, details);
		}
	};

	const handleOnBlur = (event, onBlur) => {
		onBlur();
		if (typeof autocompleteProps?.onBlur === 'function') {
			autocompleteProps.onBlur(event);
		}
		setIsFocused(false);
	};

	const handleOnInputChange = (value, newInputValue) => {
		if (multiple && value?.length === max) {
			setInputValue('');
		} else {
			setInputValue(newInputValue);
			customOnInputChange?.(newInputValue);
		}
	};

	return (
		<Controller
			name={name}
			rules={validationRules}
			render={({
				field: { onChange, onBlur, value, ref, ...fieldRest },
				fieldState: { error },
			}) => {
				const currentValue = getCurrentValue(value);
				return (
					<Autocomplete
						{...autocompleteProps}
						value={currentValue}
						loading={loading}
						multiple={multiple}
						options={options}
						filterOptions={(options) => {
							// we need disabled filtering in MUI Autocomplete
							return options;
						}}
						disableCloseOnSelect={disableCloseOnSelect(autocompleteProps, multiple)}
						isOptionEqualToValue={isOptionEqualToValue(autocompleteProps)}
						getOptionLabel={getOptionSelected(autocompleteProps)}
						onChange={(event, val, reason, details) =>
							handleOnChange(event, val, reason, details, onChange)
						}
						renderOption={renderOption(autocompleteProps, type)}
						onBlur={(event) => handleOnBlur(event, onBlur)}
						onFocus={() => {
							setIsFocused(true);
							!native && setInputValue('');
						}}
						onInputChange={(event, newInputValue) => {
							if (!event?.target) return; // protection of the pre-render react issue
							handleOnInputChange(value, newInputValue);
						}}
						renderInput={(params) => {
							return (
								<Box sx={{ position: 'relative' }}>
									{withHeading ? (
										<Heading heading={withHeading} icon={icon} selectedOption={value} />
									) : (
										''
									)}
									<RenderTextField
										name={name}
										label={label}
										required={required}
										textFieldProps={textFieldProps}
										params={params}
										error={error}
										loading={loading}
										native={native}
										value={value}
										inputValue={inputValue}
										isFocused={isFocused}
										multiple={multiple}
										ref={ref}
										isRaf={isRaf}
										rules={rules}
									/>
									<ExtendedInfo
										inputValue={inputValue}
										isFocused={isFocused}
										value={value}
										isRaf={isRaf}
										native={native}
										type={type}
									/>
									{!native &&
										(isPremade || isPersonalization) &&
										((!isFocused && value) || (isFocused && !inputValue)) && (
											<Box
												sx={{
													height: '23px',
													display: 'flex',
													width: 'calc(100% - 60px)',
													position: 'absolute',
													fontSize: '16px',
													fontWeight: 500,
													bottom: '6px',
													left: icon ? '35px' : '10px',
													overflow: 'hidden',
													whiteSpace: 'nowrap',
												}}
											>
												<Box sx={{ color: isPremade ? 'white' : 'black' }}>
													{value?.value?.name}
												</Box>
												&nbsp;
												<Box sx={{ color: 'rgb(152, 154, 158)' }}>{value?.value?.airportCode}</Box>
											</Box>
										)}
								</Box>
							);
						}}
						renderTags={(val: readonly any[], getTagProps) => renderTags(val, getTagProps)}
						limitTags={limitTags}
						disableClearable={
							multiple
								? true
								: autocompleteProps.disableClearable
									? autocompleteProps.disableClearable
									: false
						}
						{...fieldRest}
					/>
				);
			}}
		/>
	);
}

const disableCloseOnSelect = (autocompleteProps, multiple) =>
	typeof autocompleteProps?.disableCloseOnSelect === 'boolean'
		? autocompleteProps.disableCloseOnSelect
		: !!multiple;

const isOptionEqualToValue = (autocompleteProps) =>
	autocompleteProps?.isOptionEqualToValue
		? autocompleteProps.isOptionEqualToValue
		: (option, v) => {
				return v ? option?.value?.id === (v?.value?.id || v) : false;
			};

const getOptionSelected = (autocompleteProps) =>
	autocompleteProps?.getOptionLabel
		? autocompleteProps.getOptionLabel
		: (option) => {
				return `${option?.label || option}`;
			};

const renderOption = (autocompleteProps, type) => {
	return autocompleteProps?.renderOption ??
		(type === AutocompleteType.MapBoxGeocoding || type === AutocompleteType.MapBoxSearch)
		? (props, option) => (
				<li {...props}>
					<MapBoxMenuOption {...option} selectHasIcon={true} type={type} />
				</li>
			)
		: autocompleteProps?.renderOption ?? type === AutocompleteType.Airport
			? (props, option) => {
					return (
						<li {...props}>
							<AirportsMenuOption {...option} selectHasIcon={true} />
						</li>
					);
				}
			: type === AutocompleteType.Checkboxes
				? (props, option, { selected }) => (
						<li {...props}>
							<Checkbox sx={{ marginRight: 1 }} checked={selected} />
							{autocompleteProps?.getOptionLabel?.(option) || option.label || option}
						</li>
					)
				: undefined;
};

const RenderTextField = ({
	name,
	label,
	required,
	textFieldProps,
	params,
	error,
	loading,
	native,
	value,
	inputValue,
	isFocused,
	multiple,
	ref,
	isRaf,
	rules,
}) => (
	<TextField
		name={name}
		required={rules?.required ? true : required}
		label={label}
		{...textFieldProps}
		{...params}
		error={!!error}
		InputProps={{
			...params.InputProps,
			...textFieldProps?.InputProps,
			endAdornment: (
				<>
					{loading ? <PulseLoader color="gray" size={3} /> : null}
					{params.InputProps.endAdornment}
				</>
			),
			startAdornment: (
				<>
					{textFieldProps?.InputProps?.startAdornment}
					{params.InputProps.startAdornment}
				</>
			),
		}}
		inputProps={{
			...params.inputProps,
			...textFieldProps?.inputProps,
			sx: {
				opacity: !native ? (value ? '0 !important' : 1) : 1,
				'&:focus': {
					opacity: '1 !important',
				},
			},
			value: inputValue,
			'data-testid': `input-${name}`,
		}}
		placeholder={
			isFocused && value
				? ''
				: multiple
					? value
						? ''
						: textFieldProps.placeholder
					: textFieldProps.placeholder
		}
		helperText={error ? error.message : textFieldProps?.helperText}
		inputRef={ref}
		size="medium"
		margin={isRaf ? 'dense' : 'none'}
		variant="standard"
	/>
);

const ExtendedInfo = ({ native, isRaf, isFocused, value, inputValue, type }) =>
	!native && isRaf && ((!isFocused && value) || (isFocused && !inputValue)) ? (
		<Box
			sx={{
				height: '30px',
				width: 'calc(100% - 30px)',
				position: 'absolute',
				fontSize: '10px',
				color: 'rgb(0, 86, 190)',
				top: '2px',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
			}}
		>
			{type === AutocompleteType.Airport ? (
				<>
					{value?.value.name}
					<br />
					{value?.value.city},{value?.value.state?.code}
				</>
			) : type === AutocompleteType.MapBoxGeocoding ? (
				<ExtendedInfoMapBoxGeocoding value={value} />
			) : type === AutocompleteType.MapBoxSearch ? (
				<ExtendedInfoMapBoxSearch value={value} />
			) : null}
		</Box>
	) : null;

const ExtendedInfoMapBoxGeocoding = ({ value }) => {
	const addressInfo = useMemo(
		() => geocodingGetAddressInfo(value?.value),
		[value?.value, value?.value?.id],
	);

	return (
		<Box sx={{ whiteSpace: 'normal' }}>
			{value?.value?.text || value?.label}
			<br />
			{addressInfo}
		</Box>
	);
};
const ExtendedInfoMapBoxSearch = ({ value }) => {
	const addressInfo = useMemo(
		() => searchGetAddressInfo(value?.value),
		[value?.value, value?.value?.id],
	);

	return (
		<Box sx={{ whiteSpace: 'normal' }}>
			{value?.value?.name || value?.label}
			<br />
			{addressInfo}
		</Box>
	);
};

const renderTags = (val: readonly any[], getTagProps) =>
	val.map((option: any, index: number) => (
		<Chip
			label={option.label}
			{...getTagProps({ index })}
			sx={{
				backgroundColor: 'rgba(140, 192, 255, 0.15)',
				color: 'rgb(0, 86, 190)',
				borderRadius: '4px',
			}}
			deleteIcon={
				<CloseRounded
					onMouseDown={(ev) => {
						ev.stopPropagation();
					}}
					sx={{ fill: 'rgb(140, 192, 255)', fontSize: '17px !important' }}
					fontSize="small"
				/>
			}
		/>
	));
