import { useMemo } from 'react';

import {
	getAccessToken,
	useApiMyBusinessCasesGetCollection,
	useApiMyPastBusinessCasesGetCollection,
} from '@fllite-fe/api';

import { flightTypes } from '../const/flightTypes';
import { MyTripsBusinessCase } from '../types';
import { QUERY_KEY_PAST_TRIPS_NAME, QUERY_KEY_TRIPS_NAME, useAuthentication } from './useApi';

export * from './useAuthentication';

export const ITEMS_PER_PAGE = 10;

interface UseFlightsProps {
	enabled?: boolean;
	page?: number;
}

export const useFlights = ({ enabled = true, page = 1 }: UseFlightsProps = {}) => {
	const accessToken = getAccessToken();
	const { user } = useAuthentication();

	const { data, ...rest } = useApiMyBusinessCasesGetCollection<MyTripsBusinessCase[]>(
		{
			itemsPerPage: ITEMS_PER_PAGE,
			page,
		},
		{
			query: {
				enabled: !!accessToken && enabled,
				refetchOnMount: true,
				queryKey: [QUERY_KEY_TRIPS_NAME, user?.id],
			},
			request: {
				withHydra: true,
			},
		},
	);

	const flightTypeOptions = useMemo(
		() =>
			flightTypes.map((item) => ({
				label: item.name,
				value: item.id,
				constantKey: item.constantKey,
			})),
		[],
	);

	const myTrips = data?.['hydra:member'] ?? [];
	const totalItems = data?.['hydra:totalItems'] ?? 0;

	return {
		myTrips,
		flightTypeOptions,
		totalItems,
		...rest,
	};
};

export const usePastFlights = ({ enabled = true, page = 1 }: UseFlightsProps = {}) => {
	const accessToken = getAccessToken();
	const { user } = useAuthentication();

	const { data, ...rest } = useApiMyPastBusinessCasesGetCollection<MyTripsBusinessCase[]>(
		{
			itemsPerPage: ITEMS_PER_PAGE,
			page,
		},
		{
			query: {
				enabled: !!accessToken && enabled,
				queryKey: [QUERY_KEY_PAST_TRIPS_NAME, user?.id],
			},
			request: {
				withHydra: true,
			},
		},
	);

	const pastFlights = data?.['hydra:member'] ?? [];
	const totalItems = data?.['hydra:totalItems'] ?? 0;

	return {
		pastFlights,
		totalItems,
		...rest,
	};
};
