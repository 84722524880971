import styled from '@emotion/styled';

import { Button } from '../../components/Button';
import { H3 } from '../../components/Text';

export const Container = styled('div')`
	position: relative;
	width: 100%;
`;

export const InvisibleInput = styled('input')`
	position: absolute;
	left: -9999px;
`;

export const DateTimePickerContainer = styled('div')`
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 15px;

	${({ theme }) => theme.breakpoints.down('sm')} {
		width: 92vw;
		justify-content: center;
	}
`;

export const DateTimeInputContainer = styled('div')`
	${({ theme }) => theme.breakpoints.up('md')} {
		display: flex;
		justify-content: space-between;
		margin: 0 -4px;
	}
`;

export const DateTimeInputCol = styled('div')<{ hasMargin?: boolean }>`
	margin-top: ${({ hasMargin }) => (hasMargin ? '25px' : 0)};
	${({ theme }) => theme.breakpoints.up('md')} {
		width: 100%;
		margin: 0 4px;
	}
`;

export const TimePickerWrapper = styled('div')`
	position: relative;
	padding-top: 12px;
	padding-bottom: 25px;

	${({ theme }) => theme.breakpoints.up('sm')} {
		text-align: center;
	}

	${({ theme }) => theme.breakpoints.up('md')} {
		align-items: flex-end;
		display: flex;
		text-align: left;
	}

	.ui.label {
		background-color: transparent;
	}
	.ui.selection.dropdown > .dropdown.icon {
		display: none;
	}
	.ui.compact.selection.dropdown {
		background: rgba(140, 192, 255, 0.1);
		border: 1px solid #edf0f4;
		border-radius: 8px;
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		min-width: 55px;
	}
	.ui.dropdown > .text {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 160%;
		text-align: center;
		color: #353535;
	}
	.ui.selection.dropdown .menu > .item {
		display: flex;
		justify-content: center;
	}
`;

export const DateTimePickerSelection = styled('div')`
	display: flex;
	flex-direction: column;
`;

export const TimePickerBlock = styled('div')`
	${({ theme }) => theme.breakpoints.up('md')} {
		width: 50%;
	}
`;

export const TimePickerTitleBlock = styled('div')`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 160%;
	color: #353535;
	flex: none;
	order: 0;
	flex-grow: 0;
	margin: 0px 20px;
`;

export const TimePickerSelectBox = styled('div')`
	width: 150px;
	margin: auto;
	margin-bottom: 15px;

	${({ theme }) => theme.breakpoints.up('md')} {
		margin: 8px 20px 0 20px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
`;

export const MeridiemSelector = styled('div')`
	margin-top: 5px;
	${({ theme }) => theme.breakpoints.up('md')} {
		margin-left: 10px;
		margin-top: 0;
	}
`;

export const CalendarContainer = styled('div')<{ isRange?: boolean }>`
	.rdp {
		width: auto;
		--rdp-cell-size: 30px;
		--rdp-accent-color: #0056be;
	}

	.rdp-table {
		${({ theme }) => theme.breakpoints.down('md')} {
			max-width: unset;
			width: 100%;
		}
	}

	.RoundTrip .rdp-day_selected:not(.rdp-day_disabled) {
		&:before {
			content: '';
			position: absolute;
			background: linear-gradient(92.58deg, #2077e0 0%, #004ba6 100%);
			width: 30px;
			height: 30px;
			border-radius: 100px;
			left: calc(50% - 15px);
			top: 0;
			z-index: -1;
		}
		background: none;
		color: white;
	}

	.Selectable {
		.rdp-day_selected:not(.rdp-day_start):not(.rdp-day_end) {
			--overflow-size: 15px;
			background: #edf0f4 !important;
			color: #353535;
			overflow: visible;
			::before,
			::after {
				content: '';
				position: absolute;
				top: 0;
				width: calc(50% + 2 * var(--overflow-size));
				height: 100%;
				background: #edf0f4;
				border-radius: 0;
			}
			::before {
				right: calc(-50% - var(--overflow-size));
			}
			::after {
				left: calc(50% - var(--overflow-size));
				z-index: -30;
			}

			&.rdp-day_outside {
				opacity: 1;
				color: #9e9e9e;
			}
		}
		.rdp-day {
			border-radius: 0 !important;
		}
	}
	.RoundTrip.Selectable {
		.rdp-day_start,
		.rdp-day_end {
			border-radius: 50% !important;
			position: relative;
			border: none;
			overflow: visible;
		}
		.rdp-day_end:hover,
		.rdp-day_start:hover {
			background-color: transparent !important;
		}

		.rdp-day_selected.rdp-day_end:not(.rdp-day_start):after,
		.rdp-day_selected.rdp-day_start:not(.rdp-day_end):after {
			content: '';
			position: absolute;
			top: 0;
			height: 100%;
			background: #edf0f4;
			z-index: -2;
			left: -50%;
			width: 100%;
			@media screen and (min-width: 800px) {
				left: -50%;
				width: 70%;
			}
		}

		.rdp-day_selected.rdp-day_start:not(.rdp-day_end):after {
			left: 25%;
			display: ${({ isRange }) => (isRange ? 'block' : 'none')};
			@media screen and (min-width: 320px) {
				left: 50%;
			}
			@media screen and (min-width: 800px) {
				left: 50%;
				width: 70%;
			}
		}
	}

	.rdp-caption {
		margin-bottom: 8px;
	}

	.rdp-caption > div {
		font-weight: 600;
		font-size: 16px;
		line-height: 160%;
		text-align: center;
		color: #0056be;
	}
	.rdp-nav_button_previous,
	.rdp-nav_button_next {
		width: 26px;
		height: 26px;
		margin-top: 2px;
	}

	.rdp-head_cell {
		font-weight: 600;
		font-size: 12px;
		line-height: 160%;
		text-align: center;
		color: #0056be;
		flex: none;
		order: 4;
		flex-grow: 0;
		margin: 0px 10px;
	}
	.rdp-day {
		font-size: 12px;
		width: 30px;
		height: 30px;
		margin: 0;
		border: 0;
		padding: 2px;

		${({ theme }) => theme.breakpoints.down('md')} {
			width: 100%;
		}
	}
	.rdp-months {
		align-items: flex-start;
	}
	.RoundTrip.Selectable .rdp-months {
		max-width: 314px;
	}
	.rdp-month {
		${({ theme }) => theme.breakpoints.down('md')} {
			width: 100%;
		}
	}
	.rdp-week {
		margin-bottom: 2px;
	}
	.rdp-day_today {
		color: inherit;
	}
	.rdp-day_outside {
		opacity: 1;
		color: #9e9e9e;
	}
`;

export const PickerButton = styled(Button)`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 3px 10px;
	font-size: 16px;
	min-width: 100px;
	min-height: 32px;
	height: 32px;
	background: linear-gradient(92.58deg, #2077e0 0%, #004ba6 100%);
	border-radius: 8px;
	margin: auto;

	${({ theme }) => theme.breakpoints.up('md')} {
		margin: 0 0 6px 0;
	}
`;

export const StyledH3 = styled(H3)<{ isReturn?: boolean }>`
	font-weight: 300;
	white-space: nowrap;
	min-height: 34px;
	padding-top: ${({ isReturn }) => (isReturn ? '35px' : 0)};

	${({ theme }) => theme.breakpoints.up('md')} {
		padding-top: 0;
	}
`;

export const SpaceFiller = styled('div')`
	width: 1px;
	height: 70px;

	${({ theme }) => theme.breakpoints.up('md')} {
		height: 34px;
	}

	${({ theme }) => theme.breakpoints.down('sm')} {
		height: 24px;
	}
`;

export const MobileTimePickerBox = styled('div')`
	margin: 0px 20px;
	padding: 10px 0;
`;
