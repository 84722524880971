import { SearchBoxSuggestion } from '@mapbox/search-js-core/dist';
import {
	GeocodingFeature,
	GeocodingFeatureContext,
} from '@mapbox/search-js-core/dist/geocode/types';
import { SuggestionJSONContext } from '@mapbox/search-js-core/dist/searchbox/types';

interface GeocodingSimpleContextData {
	country: string;
	district: string;
	place: string;
	postcode: string;
	region: string;
	street: string;
}

const defaultContextData = {
	country: '',
	district: '',
	place: '',
	postcode: '',
	region: '',
	street: '',
} as GeocodingSimpleContextData;

const geocodingGetReadabilityContextData = (
	context: GeocodingFeatureContext[],
): GeocodingSimpleContextData => {
	if (!context) return defaultContextData;

	const contextData =
		context?.reduce?.((acc, item) => {
			const nameWithoutSuffix = item.id.split('.')[0];
			return {
				...acc,
				[nameWithoutSuffix]: item.text,
			};
		}, defaultContextData) || defaultContextData;

	return contextData;
};

const geocodingGetAddressFromContext = (context: GeocodingFeatureContext[]): string => {
	const contextData = geocodingGetReadabilityContextData(context);
	const addressInfoWithData = [
		contextData.region,
		contextData.postcode,
		contextData.place,
		contextData.street,
	].filter((item) => item);

	return addressInfoWithData.join(', ');
};

const searchGetAddressFromContext = (context: SuggestionJSONContext): string => {
	const addressInfoWithData = [
		context.region?.name,
		context.postcode?.name,
		context.place?.name,
		context.street?.name,
	].filter((item) => item);

	return addressInfoWithData.join(', ');
};

export const geocodingGetAddressInfo = (data: GeocodingFeature): string => {
	if (!data?.context) return '';

	const readabilityContext = geocodingGetReadabilityContextData(data.context);
	const isInContextOnlyCountryInfo = data.context.length === 1 && readabilityContext.country; // if only country info is available
	const isItRegion = data.place_type?.includes('region');
	if (isItRegion && isInContextOnlyCountryInfo) return 'Region';

	return geocodingGetAddressFromContext(data.context);
};

export const searchGetAddressInfo = (data: SearchBoxSuggestion): string => {
	if (!data?.context) return '';

	const isInContextOnlyCountryInfo =
		Object.values(data.context).length === 1 && data.context.country; // if only country info is available
	const isItRegion = data.feature_type === 'region';
	if (isItRegion && isInContextOnlyCountryInfo) return 'Region';

	return searchGetAddressFromContext(data.context);
};
