import { FC, ReactNode } from 'react';

import styled from '@emotion/styled';
import { Dayjs } from 'dayjs';

import { formatDate } from '@fllite-fe/shared/src/utils/datetime';

import { FieldError } from '../../components/FieldError';
import { Icon, IconKeys } from '../../components/Icon';
import { Text } from '../../components/Text';

interface InputProps {
	hasError: boolean;
	open: boolean;
	inverted?: boolean;
	hasIcon?: boolean;
}

const Container = styled('div')`
	position: relative;
`;

const StyledIcon = styled(Icon)`
	position: absolute;
	top: 8px;
	left: 5px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 16px;
		height: 16px;
	}
`;
const inputPropArr = ['hasError', 'open', 'inverted', 'hasIcon'];
const Input = styled('div', {
	shouldForwardProp: (prop: keyof InputProps) => !inputPropArr.includes(prop),
})<InputProps>`
	width: 100%;
	color: ${({ theme, inverted }) => (inverted ? '#ffffff' : theme.colors.grayDarker)};
	font-size: 15px;
	line-height: 1.56;
	font-weight: 500;
	border-radius: 0;
	border: none;
	min-height: ${({ hasIcon }) => (hasIcon ? '38px' : '35px')};
	height: ${({ hasIcon }) => (hasIcon ? 'auto' : '35px')};
	border-bottom: 1px solid
		${({ theme, hasError, open, inverted }) =>
			hasError
				? theme.colors.danger
				: open
					? inverted
						? '#ffffff'
						: theme.colors.primary
					: inverted
						? theme.colors.primaryLight
						: theme.colors.gray};
	padding: ${({ hasIcon }) => (hasIcon ? '6px 10px 6px 27px' : '6px 10px 6px 0px')};
	outline: none;
	box-sizing: border-box;
	cursor: pointer;
	transition: border-bottom ${({ theme }) => theme.transition.baseTransition};

	:focus {
		border-bottom: 1px solid
			${({ theme, inverted }) => (inverted ? '#ffffff' : theme.colors.primary)};
	}

	::placeholder {
		color: ${({ theme }) => theme.colors.gray};
	}
`;

const ErrorMessage = styled(Text)`
	display: block;
	margin: 6px 0 0 10px;
`;

const ShortTime = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
`;

const Placeholder = styled(Text, {
	shouldForwardProp: (prop) => prop !== 'hasIcon',
})<{ hasIcon?: boolean }>`
	font-weight: ${({ hasIcon }) => (hasIcon ? 500 : 400)};
	color: ${({ hasIcon, theme }) => (hasIcon ? '#c0c3c6' : theme.colors.grayDark)};
	font-size: ${({ hasIcon }) => (hasIcon ? '15px !important' : '12px !important')};
	letter-spacing: ${({ hasIcon }) => (hasIcon ? 'normal' : '1px')};
`;

const StyledText = styled(Text)`
	letter-spacing: 0.5px;
`;

interface DateTimeProps {
	value?: Dayjs;
	hasError?: boolean;
	errorMessage?: string;
	open: boolean;
	inverted?: boolean;
	shortTime?: boolean;
	icon?: IconKeys;
	placeholder?: ReactNode;
	hasIcon?: boolean;
	rafFormPrimary?: 'Departure' | 'Return' | undefined;
}

export const DateTimeInput: FC<DateTimeProps> = ({
	value,
	hasError,
	errorMessage,
	open,
	inverted,
	shortTime,
	icon,
	placeholder,
	rafFormPrimary,
	hasIcon = true,
}) => (
	<Container>
		{hasIcon && (
			<StyledIcon type={icon != null ? icon : inverted ? 'calendar-blue-light' : 'calendar-blue'} />
		)}

		<Input
			hasError={hasError}
			open={open}
			inverted={inverted}
			hasIcon={hasIcon}
			data-testid="calendar-input"
		>
			{value ? (
				shortTime ? (
					<ShortTime>
						<Text gray={!inverted}>{value.format('ddd, DD MMM')}</Text>{' '}
						<Text gray={!inverted} small>
							{value.format('h:mm a')}
						</Text>
					</ShortTime>
				) : rafFormPrimary ? (
					<StyledText subtext primary>
						{rafFormPrimary}, {value.format('hh:mm a')}
					</StyledText>
				) : (
					<>{value.format('MMM DD, YYYY h:mm a')}</>
				)
			) : (
				<Placeholder gray hasIcon={hasIcon}>
					{placeholder ?? formatDate(new Date(), 'MMM DD, YYYY h:mm a')}
				</Placeholder>
			)}
		</Input>

		{hasError && (
			<ErrorMessage danger subtext data-testid="error-message" className="Mui-error">
				<FieldError error={errorMessage} />
			</ErrorMessage>
		)}
	</Container>
);
