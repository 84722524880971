import { ChangeEvent, FC } from 'react';

import styled from '@emotion/styled';
import { AirplanemodeActiveOutlined, ConnectingAirportsOutlined } from '@mui/icons-material';
import {
	Box,
	FormControl,
	FormControlLabel,
	FormControlLabelProps,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	Stack,
	useTheme,
} from '@mui/material';
import { Control, FieldError, Path, useController } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export enum RadioButtonIcon {
	OneWay = 'one-way',
	RoundTrip = 'round-trip',
}

interface CustomFormControlLabelProps extends FormControlLabelProps {
	twoOptions?: boolean;
}

const CustomFormControlLabel = styled(FormControlLabel)<CustomFormControlLabelProps>`
	cursor: pointer;
	padding: 5px 15px;
	font-weight: 500;
	line-height: 25px;
	font-size: 14px;
	color: ${({ checked, theme }) => (checked ? '#fff' : theme.colors.primary)};
	background: ${({ theme, checked }) =>
		checked ? theme.backgrounds.primaryGradient : 'rgba(140, 192, 255, 0.15)'};
	transition:
		background ${({ theme }) => theme.transition.baseTransition},
		border ${({ theme }) => theme.transition.baseTransition};

	border-radius: 4px;
	overflow: hidden;
	margin: 0 8px;

	&:hover {
		color: ${({ theme, checked }) => !checked && theme.colors.grayDarker};

		&:after {
			opacity: ${({ checked }) => (checked ? '0.1' : '0')};
		}
	}

	.MuiFormControlLabel-label {
		font-weight: 500;
	}

	${({ theme }) => theme.breakpoints.down('sm')} {
		padding: 5px 6px;
		margin: 0 4px;
		width: ${({ twoOptions }) => (twoOptions ? 'calc(50% - 8px)' : 'auto')};
		justify-content: ${({ twoOptions }) => (twoOptions ? 'center' : 'flex-start')};
		.MuiFormControlLabel-label {
			font-size: 14px;
		}
	}
`;

export type RadioButtonGroupProps<T extends FieldValues> = {
	options: { label: string; id: string | number; icon?: RadioButtonIcon }[] | any[];
	helperText?: string;
	name: Path<T>;
	required?: boolean;
	parseError?: (error: FieldError) => string;
	label?: string;
	labelKey?: string;
	valueKey?: string;
	type?: 'number' | 'string';
	emptyOptionLabel?: 'string';
	onChange?: (value: any) => void;
	returnObject?: boolean;
	row?: boolean;
	control?: Control<T>;
};

export default function RadioButtonGroup<TFieldValues extends FieldValues>({
	helperText,
	options,
	label,
	name,
	parseError,
	labelKey = 'label',
	valueKey = 'value',
	required,
	emptyOptionLabel,
	returnObject,
	row,
	control,
	...rest
}: RadioButtonGroupProps<TFieldValues>) {
	const theme = useTheme();
	const {
		field: { value, onChange },
		fieldState: { invalid, error },
	} = useController({
		name,
		rules: required ? { required: 'This field is required' } : undefined,
		control,
	});

	helperText = error
		? typeof parseError === 'function'
			? parseError(error)
			: error.message
		: helperText;

	const onRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
		const radioValue = (event.target as HTMLInputElement).value;
		const returnValue = returnObject
			? options.find((items) => items[valueKey] == radioValue)
			: radioValue;

		// setValue(name, returnValue, { shouldValidate: true })
		onChange(returnValue);
		if (typeof rest.onChange === 'function') {
			rest.onChange(returnValue);
		}
	};

	const twoOptions = options.length === 2;

	return (
		<FormControl error={invalid}>
			{label && (
				<FormLabel required={required} error={invalid}>
					{label}
				</FormLabel>
			)}
			<RadioGroup onChange={onRadioChange} name={name} row={row} value={value || ''}>
				{emptyOptionLabel && (
					<FormControlLabel
						control={
							<Radio
								sx={{
									...(invalid ? { color: theme.palette.error.main } : {}),
								}}
								checked={!value}
							/>
						}
						label={emptyOptionLabel}
						value=""
					/>
				)}
				{options.map((option: any) => {
					const optionKey = option[valueKey];
					if (!optionKey) {
						console.error(
							`CheckboxButtonGroup: valueKey ${valueKey} does not exist on option`,
							option,
						);
					}
					const isChecked = !!(
						value && (returnObject ? value[valueKey] == optionKey : value == optionKey)
					);
					return (
						<CustomFormControlLabel
							control={<Radio sx={{ display: 'none' }} />}
							value={optionKey}
							key={optionKey}
							label={
								<Stack gap={1} direction="row">
									{option.icon && <Icon type={option.icon} />}
									<Box>{option[labelKey]}</Box>
								</Stack>
							}
							checked={isChecked}
							twoOptions={twoOptions}
						/>
					);
				})}
			</RadioGroup>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
}

const FlightTypeIconWrapper = styled.div<{ rotate?: boolean }>`
	display: flex;
	align-items: center;
	& svg {
		width: 16px;
		height: 16px;
		align-self: center;
		${({ rotate }) => rotate && `transform: rotate(90deg)`};
	}
`;

const Icon: FC<{ type: RadioButtonIcon }> = ({ type }) => {
	return (
		<FlightTypeIconWrapper rotate={type === RadioButtonIcon.OneWay}>
			{type === RadioButtonIcon.OneWay ? (
				<AirplanemodeActiveOutlined />
			) : (
				<ConnectingAirportsOutlined />
			)}
		</FlightTypeIconWrapper>
	);
};
