import { FC, useMemo } from 'react';

import styled from '@emotion/styled';

import { Icon } from '../../components/Icon';
import { Text } from '../../components/Text';
import { geocodingGetAddressInfo, searchGetAddressInfo } from '../../utils/mapbox';
import { AutocompleteType } from '.';

type OptionProps = {
	label: string;
	value: any;
	selectHasIcon?: boolean;
	isSelected: boolean;
	type: AutocompleteType.MapBoxSearch | AutocompleteType.MapBoxGeocoding;
};

const Container = styled('div')<{ selectHasIcon?: boolean }>`
	padding: 5px 0px 3px;
	border-radius: 4px;
	outline: none;
	font-weight: normal;
	transition: background ${({ theme }) => theme.transition.baseTransition};
	cursor: pointer;
`;

const StyledIcon = styled(Icon)`
	width: 15px;

	svg {
		width: 15px;
	}
`;

const BlankIcon = styled('div')`
	width: 15px;
`;

const OptionWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 10px;
`;

const AirportInfoWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-left: 17px;
`;

const UpperlineWrapper = styled('div')`
	display: flex;
	flex-direction: row;
`;

export const ANYWHERE = 'Anywhere';
const ANYWHERE_TITLE = 'Not sure where to travel?';
const ANYWHERE_DESCRIPTION = 'Get inspired - set Anywhere';

export const MapBoxMenuOption: FC<OptionProps> = ({
	label,
	value,
	selectHasIcon,
	isSelected,
	type,
}) => {
	const infoIcon = useMemo(() => {
		switch (true) {
			case isSelected:
				return <StyledIcon type="check-primary" />;
			case value.properties?.category?.includes('airport') ||
				value?.poi_category?.includes('airport'):
				return <StyledIcon type={'aircraft-filled-gray'} />;
			default:
				return <BlankIcon />;
		}
	}, [isSelected, value?.properties?.category]);

	const title = value.text || value?.place_name || value?.name;

	const addressInfo = useMemo(
		() =>
			title === ANYWHERE && type === AutocompleteType.MapBoxSearch
				? ANYWHERE_DESCRIPTION
				: type === AutocompleteType.MapBoxGeocoding
					? geocodingGetAddressInfo(value)
					: searchGetAddressInfo(value),
		[value, value.id],
	);

	const upgradedTitle = title === ANYWHERE ? ANYWHERE_TITLE : title;

	return (
		<Container
			selectHasIcon={selectHasIcon}
			data-testid="multi-select-option"
			data-testvalue={label}
		>
			<OptionWrapper>
				{infoIcon}
				<AirportInfoWrapper>
					<UpperlineWrapper>
						<Text gray medium>
							{upgradedTitle}
						</Text>
					</UpperlineWrapper>
					<Text gray subtext>
						{addressInfo}
					</Text>
				</AirportInfoWrapper>
			</OptionWrapper>
		</Container>
	);
};
