import { FC, memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styled from '@emotion/styled';
import { Box, Popover, Select, TextField, useMediaQuery } from '@mui/material';
import { addToRange, DayPicker } from 'react-day-picker';
import { Controller, useFormContext } from 'react-hook-form';

import { guessTimezone, toIsoStringAfterFormatWithTz } from '@fllite-fe/shared/src/utils/datetime';
import dayjs from '@fllite-fe/shared/src/utils/extendedDayjs';

import { IconKeys } from '../../components/Icon';
import { Text } from '../../components/Text';
import { muiTheme } from '../../muiTheme';
import { DateTimeInput } from './DateTimeInput';
import {
	CalendarContainer,
	Container,
	DateTimeInputCol,
	DateTimeInputContainer,
	DateTimePickerContainer,
	DateTimePickerSelection,
	InvisibleInput,
	MeridiemSelector,
	MobileTimePickerBox,
	PickerButton,
	SpaceFiller,
	StyledH3,
	TimePickerBlock,
	TimePickerSelectBox,
	TimePickerTitleBlock,
	TimePickerWrapper,
} from './styled';
import {
	dateToDayjs,
	getDate,
	getDateString,
	getMobileTimeText,
	hours12to24,
	parseValue,
} from './utils';

export const TitleInputContainer = styled('div')`
	padding-left: 10px;
	margin: 1rem 0 1rem;
	font-weight: 500;
	font-size: 12px;
	line-height: 160%;
	display: flex;
	align-items: center;
`;

export type MeridiemsType = 'AM' | 'PM';

const hoursUS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const minutesArray = ['00', '15', '30', '45'];
const meridiems: MeridiemsType[] = ['AM', 'PM'];

type DisabledDaysType = {
	before?: Date;
	after?: Date;
};

interface DateTimePickerProps {
	header?: ReactNode;
	timezone?: string;
	forceIsErrorShown?: boolean;
	disabledDays?: DisabledDaysType;
	inverted?: boolean;
	shortTime?: boolean;
	icon?: IconKeys;
	withTitle?: boolean;
	withLargeTitle?: boolean;
	placeholder?: ReactNode;
	returnPlaceholder?: ReactNode;
	namePrefix?: string;
	fromName: string;
	toName?: string;
	isRoundTrip?: boolean;
	setDateStart: (name: string, val: string) => void;
	setDateEnd?: (name: string, val: string) => void;
	destinationTimezone?: string;
}

const DateTimePicker: FC<DateTimePickerProps> = memo(
	({
		timezone,
		inverted,
		shortTime,
		icon,
		isRoundTrip,
		withTitle,
		withLargeTitle,
		placeholder,
		returnPlaceholder,
		setDateStart,
		setDateEnd,
		destinationTimezone,
		namePrefix = '',
		fromName,
		toName,
	}) => {
		const { control, watch, trigger } = useFormContext();
		const fromValue = watch(namePrefix + fromName);
		const toValue = watch(namePrefix + toName);

		const [state, setState] = useState<{ from: any; to: any }>({
			from: getDate(fromValue),
			to: getDate(toValue),
		});

		const [fromHours, setFromHours] = useState(12);
		const [fromMinutes, setFromMinutes] = useState(0);
		const [toHours, setToHours] = useState(12);
		const [toMinutes, setToMinutes] = useState(0);

		useEffect(() => {
			setDateStart(namePrefix, getDateString(state.from));
			setDateEnd?.(namePrefix, getDateString(state.to));
		}, [state?.from, state?.to]);

		useEffect(() => {
			if (fromValue) {
				const dateInDayjs = dayjs(fromValue).tz(finalTimezone);
				setFromHours(dateInDayjs?.hour());
				setFromMinutes(dateInDayjs?.minute());
			}
		}, [fromValue]);

		useEffect(() => {
			if (toValue) {
				const dateInDayjs = dayjs(toValue).tz(finalDestinationTimezone);
				setToHours(dateInDayjs?.hour());
				setToMinutes(dateInDayjs?.minute());
			}
		}, [toValue]);

		const [open, setOpen] = useState(false);
		const today = new Date();

		const containerRef = useRef<HTMLDivElement | undefined>();
		const inputRef = useRef<HTMLInputElement | undefined>();
		const datePickerRef = useRef<HTMLDivElement | undefined>();
		const isDesktop = useMediaQuery(muiTheme.breakpoints.up('sm'));
		const numberOfMonths = isDesktop ? 2 : 1;

		const timeOptions = hoursUS.reduce((acc, hour) => {
			const hourOption = minutesArray.map((minute) => ({
				text: `${hour}:${minute}`,
				value: `${hour}:${minute}`,
			}));

			return [...acc, ...hourOption];
		}, []);

		const meridiamOptions = meridiems.map((meridiem) => ({
			text: meridiem,
			value: meridiem,
		}));

		let finalTimezone = timezone;
		let finalDestinationTimezone = destinationTimezone;

		if (!finalTimezone) {
			finalTimezone = guessTimezone();
		}

		if (!finalDestinationTimezone) {
			finalDestinationTimezone = guessTimezone();
		}

		const { meridiem } = useMemo(() => parseValue(fromValue, finalTimezone), [fromValue]);

		const handleClose = useCallback(() => {
			setOpen(false);
			trigger([`${namePrefix}${fromName}`, `${namePrefix}${toName}`]);
		}, [setOpen, trigger, namePrefix, fromName, toName]);

		const handleOpen = useCallback(() => {
			setOpen(true);
			focusInputRefIfYouCan(inputRef);
		}, [setOpen, inputRef]);

		const handleFocus = useCallback(() => {
			setOpenIfIsClosed(open, setOpen);
		}, [open, setOpen]);

		const handleBlur = useCallback(
			(event) => {
				catchBlurHandle({
					event,
					containerRef,
					inputRef,
					setOpen,
				});
			},
			[containerRef, inputRef, setOpen],
		);

		const addDepartureTimeToRange = useCallback(
			(e) => {
				const localTime = dayjs(e.target.value, 'HH:mm');
				const localHours = localTime.hour();
				const localMinutes = localTime.minute();
				setFromHours(localHours);
				setFromMinutes(localMinutes);

				const { from } = state;
				if (from) {
					const updatedTime = new Date(
						dayjs
							.tz(from.toISOString(), finalTimezone)
							.hour(localHours)
							.minute(localMinutes)
							.toISOString(),
					);
					setState({ ...state, from: updatedTime });
				}
			},
			[state, finalTimezone],
		);

		const addDepartureMeridiamToRange = useCallback(
			(e) => {
				const localMeridiem: MeridiemsType = e.target.value;
				const { from } = state;

				if (from) {
					const valueInDayjs = dayjs.tz(from, finalTimezone);

					const hours24to12 = ((valueInDayjs.hour() + 11) % 12) + 1;
					const updatedTime = new Date(
						dayjs
							.tz(from.toISOString(), finalTimezone)
							.hour(hours12to24(hours24to12, localMeridiem))
							.toISOString(),
					);

					setState({ ...state, from: updatedTime });
				}
			},
			[state, finalTimezone],
		);

		const addReturnTimeToRange = useCallback(
			(e) => {
				const localTime = dayjs(e.target.value, 'HH:mm');
				const localHours = localTime.hour();
				const localMinutes = localTime.minute();
				setToHours(localHours);
				setToMinutes(localMinutes);

				const { to } = state;
				if (to) {
					const updatedTime = new Date(
						dayjs
							.tz(to.toISOString(), finalDestinationTimezone)
							.hour(localHours)
							.minute(localMinutes)
							.toISOString(),
					);

					setState({ ...state, to: updatedTime });
				}
			},
			[state, finalDestinationTimezone],
		);

		const addReturnMeridiamToRange = useCallback(
			(e) => {
				const localMeridiem: MeridiemsType = e.target.value;
				const { to } = state;

				if (to) {
					const valueInDayjs = dayjs.tz(to, finalDestinationTimezone);
					const hours24to12 = ((valueInDayjs.hour() + 11) % 12) + 1;
					const updatedTime = new Date(
						dayjs
							.tz(to.toISOString(), finalDestinationTimezone)
							.hour(hours12to24(hours24to12, localMeridiem))
							.toISOString(),
					);
					setState({ ...state, to: updatedTime });
				}
			},
			[state, finalDestinationTimezone],
		);

		const setDay = useCallback(
			(selectedDay) => {
				makeStateForSetDay({
					selectedDay,
					today,
					fromValue,
					finalTimezone,
					setState,
					state,
				});
			},
			[today, fromValue, finalTimezone, setState, state],
		);

		const handleRangeClick = useCallback(
			(dateRange) => {
				makeUpdateAfterHandleRangeClick({
					dateRange,
					today,
					state,
					finalTimezone,
					fromHours,
					fromMinutes,
					toHours,
					toMinutes,
					setState,
				});
			},
			[today, state, finalTimezone, fromHours, fromMinutes, toHours, toMinutes, setState],
		);

		const setTime = useCallback(
			(e) => {
				makeStateUpdateForSetTime({
					e,
					setState,
					state,
					isDesktop,
					meridiem,
					fromValue,
					finalTimezone,
				});
			},
			[setState, state, isDesktop, meridiem, fromValue, finalTimezone],
		);

		const setMeridiem = useCallback(
			(e) => {
				const localMeridiem: MeridiemsType = e.target.value;
				const valueInDayjs = dayjs.tz(fromValue, finalTimezone);
				const hours24to12 = ((valueInDayjs.hour() + 11) % 12) + 1;

				setState({
					...state,
					from: valueInDayjs.hour(hours12to24(hours24to12, localMeridiem)).toISOString(),
				});
			},
			[state, fromValue, finalTimezone],
		);

		const { from, to } = state;

		const { day: fromDay, meridiem: fromMeridiem } = parseValue(from, finalTimezone);
		const { day: toDay, meridiem: toMeridiem } = parseValue(to, finalDestinationTimezone);
		const modifiers = {
			start: fromDay,
			end: toDay,
		};

		return (
			<Component
				handleFocus={handleFocus}
				handleBlur={handleBlur}
				inputRef={inputRef}
				isDesktop={isDesktop}
				datePickerRef={datePickerRef}
				handleClose={handleClose}
				open={open}
				namePrefix={namePrefix}
				containerRef={containerRef}
				isRoundTrip={isRoundTrip}
				numberOfMonths={numberOfMonths}
				fromDay={fromDay}
				toDay={toDay}
				modifiers={modifiers}
				setDay={setDay}
				today={today}
				handleRangeClick={handleRangeClick}
				fromHours={fromHours}
				fromMinutes={fromMinutes}
				addDepartureTimeToRange={addDepartureTimeToRange}
				setTime={setTime}
				timeOptions={timeOptions}
				from={from}
				fromMeridiem={fromMeridiem}
				addDepartureMeridiamToRange={addDepartureMeridiamToRange}
				setMeridiem={setMeridiem}
				meridiamOptions={meridiamOptions}
				toHours={toHours}
				toMinutes={toMinutes}
				addReturnTimeToRange={addReturnTimeToRange}
				to={to}
				toMeridiem={toMeridiem}
				addReturnMeridiamToRange={addReturnMeridiamToRange}
				fromValue={fromValue}
				toValue={toValue}
				handleOpen={handleOpen}
				withLargeTitle={withLargeTitle}
				fromName={fromName}
				control={control}
				finalTimezone={finalTimezone}
				inverted={inverted}
				icon={icon}
				shortTime={shortTime}
				withTitle={withTitle}
				toName={toName}
				finalDestinationTimezone={finalDestinationTimezone}
				returnPlaceholder={returnPlaceholder}
				placeholder={placeholder}
			/>
		);
	},
);

const getRangeWithSaveTime = ({
	dateRange,
	today,
	state,
	finalTimezone,
	fromHours,
	fromMinutes,
	toHours,
	toMinutes,
}) => {
	if (dateRange < today) {
		return null;
	}

	const val =
		state.from && state.to
			? { from: undefined, to: undefined }
			: { from: state.from, to: state.to };

	const range = addToRange(dateRange, val);

	return {
		from: range.from
			? dayjs
					.tz(toIsoStringAfterFormatWithTz(range.from, finalTimezone), finalTimezone)
					.hour(fromHours)
					.minute(fromMinutes)
					.toDate()
			: range.from,
		to: range.to
			? dayjs
					.tz(toIsoStringAfterFormatWithTz(range.to, finalTimezone), finalTimezone)
					.hour(toHours)
					.minute(toMinutes)
					.toDate()
			: range.to,
	};
};

const focusInputRefIfYouCan = (inputRef) => {
	if (inputRef.current != null) {
		inputRef.current.focus();
	}
};

const setOpenIfIsClosed = (open, setOpen) => {
	if (!open) {
		setOpen(true);
	}
};

const catchBlurHandle = ({ event, containerRef, inputRef, setOpen }) => {
	if (
		containerRef.current?.contains((event.relatedTarget ?? document.activeElement) as HTMLElement)
	) {
		inputRef.current.focus();
		return;
	}

	if (open) {
		setOpen(false);
	}
};

const makeUpdateAfterHandleRangeClick = ({
	dateRange,
	today,
	state,
	finalTimezone,
	fromHours,
	fromMinutes,
	toHours,
	toMinutes,
	setState,
}) => {
	const rangeWithSaveTime = getRangeWithSaveTime({
		dateRange,
		today,
		state,
		finalTimezone,
		fromHours,
		fromMinutes,
		toHours,
		toMinutes,
	});

	if (!rangeWithSaveTime) return;
	setState(rangeWithSaveTime);
};

const makeStateUpdateForSetTime = ({
	e,
	setState,
	state,
	isDesktop,
	meridiem,
	fromValue,
	finalTimezone,
}) => {
	const localTime = dayjs(e.target.value, 'HH:mm');
	const localHours = localTime.hour();
	const localMinutes = localTime.minute();
	const valueInDayjs = dayjs.tz(fromValue, finalTimezone);

	setState({
		...state,
		from: valueInDayjs
			.hour(isDesktop ? hours12to24(localHours, meridiem) : localHours)
			.minute(localMinutes)
			.toISOString(),
	});
};

const makeStateForSetDay = ({ selectedDay, today, fromValue, finalTimezone, setState, state }) => {
	if (selectedDay < today) {
		return null;
	}

	const valueInDayjs = dayjs.tz(fromValue, finalTimezone);
	const hours = fromValue ? valueInDayjs.hour() : 0;
	const minutes = fromValue ? valueInDayjs.minute() : 0;
	const dayInDayjs = dateToDayjs(selectedDay, finalTimezone);

	setState({
		...state,
		from: dayInDayjs.hour(hours).minute(minutes).toDate(),
	});
};
const Component = ({
	handleFocus,
	handleBlur,
	inputRef,
	isDesktop,
	datePickerRef,
	handleClose,
	open,
	namePrefix,
	containerRef,
	isRoundTrip,
	numberOfMonths,
	fromDay,
	toDay,
	modifiers,
	setDay,
	today,
	handleRangeClick,
	fromHours,
	fromMinutes,
	addDepartureTimeToRange,
	setTime,
	timeOptions,
	from,
	fromMeridiem,
	addDepartureMeridiamToRange,
	setMeridiem,
	meridiamOptions,
	toHours,
	toMinutes,
	addReturnTimeToRange,
	to,
	toMeridiem,
	addReturnMeridiamToRange,
	fromValue,
	toValue,
	handleOpen,
	withLargeTitle,
	fromName,
	control,
	finalTimezone,
	inverted,
	icon,
	shortTime,
	withTitle,
	toName,
	finalDestinationTimezone,
	returnPlaceholder,
	placeholder,
}) => (
	<Container>
		<InvisibleInput
			type="text"
			onFocus={handleFocus}
			onBlur={handleBlur}
			ref={inputRef}
			data-testid={`calendar-hidden-input-${namePrefix}`}
			disabled
		/>
		<Popover
			anchorReference={isDesktop ? undefined : 'anchorPosition'}
			anchorEl={datePickerRef.current}
			onClose={handleClose}
			open={open}
			keepMounted
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			anchorPosition={{ top: 0, left: 0 }}
			disableAutoFocus
		>
			<DateTimePickerContainer
				data-testid={`popup-container-${namePrefix}`}
				ref={containerRef}
				tabIndex={0}
			>
				<DateTimePickerSelection>
					<CalendarContainer isRange={isRoundTrip}>
						<DayPicker
							className={`Selectable ${isRoundTrip ? 'RoundTrip' : ''}`}
							numberOfMonths={numberOfMonths}
							selected={setSelectedDays({ isRoundTrip, fromDay, toDay })}
							modifiers={modifiers}
							modifiersClassNames={{
								start: 'rdp-day_start',
								end: 'rdp-day_end',
							}}
							disabled={{ before: today }}
							onDayClick={isRoundTrip ? handleRangeClick : setDay}
							showOutsideDays
							ISOWeek
							fromMonth={new Date()}
							defaultMonth={from}
						/>
					</CalendarContainer>
					<TimePickerWrapper>
						<TimePickerBlock>
							<TimePickerTitleBlock>Departure time</TimePickerTitleBlock>
							{isDesktop ? (
								<TimePickerSelectBox>
									<Select
										native
										data-testid="daypicker-time-select"
										value={dayjs(`${fromHours}:${fromMinutes}`, 'hh:mm').format('hh:mm')}
										placeholder="00:00"
										onChange={isRoundTrip ? addDepartureTimeToRange : setTime}
									>
										{timeOptions.map((item) => (
											<option value={item.value} key={item.value}>
												{item.text}
											</option>
										))}
									</Select>

									<MeridiemSelector>
										<Select
											native
											data-testid="daypicker-ampm-select"
											value={from ? fromMeridiem : undefined}
											placeholder="AM"
											onChange={isRoundTrip ? addDepartureMeridiamToRange : setMeridiem}
										>
											{meridiamOptions.map((item) => (
												<option value={item.value} key={item.value}>
													{item.text}
												</option>
											))}
										</Select>
									</MeridiemSelector>
								</TimePickerSelectBox>
							) : (
								<MobileTimePickerBox>
									<TextField
										type="time"
										onChange={isRoundTrip ? addDepartureTimeToRange : setTime}
										value={
											fromHours.toString().padStart(2, '0') +
											':' +
											fromMinutes.toString().padStart(2, '0')
										}
										sx={{
											'& .MuiInputBase-root': {
												'& fieldset': {
													background: 'rgba(140, 192, 255, 0.1)',
													border: '1px solid #edf0f4',
												},
											},
											width: '130px',
										}}
										inputProps={{
											sx: {
												fontSize: '12px',
												py: '12px',
											},
										}}
									/>
								</MobileTimePickerBox>
							)}
						</TimePickerBlock>
						{isRoundTrip && (
							<TimePickerBlock>
								<TimePickerTitleBlock>Return time</TimePickerTitleBlock>
								{isDesktop ? (
									<TimePickerSelectBox>
										<Select
											native
											data-testid="daypicker-time-select"
											value={dayjs(`${toHours}:${toMinutes}`, 'hh:mm').format('hh:mm')}
											placeholder="00:00"
											onChange={addReturnTimeToRange}
										>
											{timeOptions.map((item) => (
												<option value={item.value} key={item.value}>
													{item.text}
												</option>
											))}
										</Select>

										<MeridiemSelector>
											<Select
												native
												data-testid="daypicker-ampm-select"
												value={to ? toMeridiem : undefined}
												placeholder="AM"
												onChange={addReturnMeridiamToRange}
											>
												{meridiamOptions.map((item) => (
													<option value={item.value} key={item.value}>
														{item.text}
													</option>
												))}
											</Select>
										</MeridiemSelector>
									</TimePickerSelectBox>
								) : (
									<MobileTimePickerBox>
										<TextField
											type="time"
											onChange={addReturnTimeToRange}
											value={
												toHours.toString().padStart(2, '0') +
												':' +
												toMinutes.toString().padStart(2, '0')
											}
											sx={{
												'& .MuiInputBase-root': {
													'& fieldset': {
														background: 'rgba(140, 192, 255, 0.1)',
														border: '1px solid #edf0f4',
													},
												},
												width: '130px',
											}}
											inputProps={{
												sx: {
													fontSize: '12px',
													py: '12px',
												},
											}}
										/>
									</MobileTimePickerBox>
								)}
							</TimePickerBlock>
						)}
						{!isDesktop && (
							<Box
								sx={{
									borderTop: '2px solid #DDDFE3',
									margin: '10px 20px',
									padding: '10px 0',
									fontSize: '12px',
									fontWeight: 500,
								}}
							>
								<Box sx={{ display: 'flex' }}>
									<Box sx={{ color: '#353535', width: '70px' }}>Departure:</Box>
									<Box sx={{ color: '#0056BE' }}>
										{getMobileTimeText(fromValue, fromHours, fromMinutes)}
									</Box>
								</Box>
								{isRoundTrip && (
									<Box sx={{ display: 'flex' }}>
										<Box sx={{ color: '#353535', width: '70px' }}>Return:</Box>
										<Box sx={{ color: '#0056BE' }}>
											{getMobileTimeText(toValue, toHours, toMinutes)}
										</Box>
									</Box>
								)}
							</Box>
						)}
						<PickerButton onClick={handleClose}>Done</PickerButton>
					</TimePickerWrapper>
				</DateTimePickerSelection>
			</DateTimePickerContainer>
		</Popover>
		<DateTimeInputContainer onClick={handleOpen} ref={datePickerRef}>
			<DateTimeInputCol hasMargin={!withLargeTitle}>
				{withTitle && (
					<Text subtext primary medium>
						Departure Date & Time
					</Text>
				)}

				<Controller
					name={`${namePrefix}${fromName}`}
					control={control}
					render={({
						field: { value: val },
						fieldState: { error },
						formState: { isSubmitted },
					}) => {
						return (
							<ControllerRender
								withLargeTitle={withLargeTitle}
								val={val}
								finalTimezone={finalTimezone}
								open={open}
								error={isSubmitted ? error : false}
								inverted={inverted}
								icon={icon}
								shortTime={shortTime}
								placeholder={placeholder}
							/>
						);
					}}
				/>
			</DateTimeInputCol>
			{isRoundTrip && (
				<DateTimeInputCol hasMargin={!withLargeTitle}>
					{withTitle && (
						<Text subtext primary medium>
							Return Date & Time
						</Text>
					)}
					<Controller
						name={`${namePrefix}${toName}`}
						control={control}
						render={({
							field: { value: val },
							fieldState: { error },
							formState: { isSubmitted },
						}) => {
							return (
								<ControllerRenderForRoundTrip
									withLargeTitle={withLargeTitle}
									val={val}
									finalTimezone={finalTimezone}
									open={open}
									error={isSubmitted ? error : false}
									inverted={inverted}
									icon={icon}
									shortTime={shortTime}
									finalDestinationTimezone={finalDestinationTimezone}
									returnPlaceholder={returnPlaceholder}
								/>
							);
						}}
					/>
				</DateTimeInputCol>
			)}
		</DateTimeInputContainer>
	</Container>
);

const setSelectedDays = ({ isRoundTrip, fromDay, toDay }) =>
	isRoundTrip ? [fromDay, { from: fromDay, to: toDay }] : fromDay;

const ControllerRender = ({
	withLargeTitle,
	val,
	finalTimezone,
	open,
	error,
	inverted,
	icon,
	shortTime,
	placeholder,
}) => (
	<>
		{withLargeTitle &&
			(!val ? (
				<StyledH3 grayDark>Date & Time</StyledH3>
			) : (
				<StyledH3 data-testid="daypicker-date" primary>
					{dayjs.tz(val, finalTimezone).format('MMM DD')}
				</StyledH3>
			))}
		<DateTimeInput
			value={val ? dayjs.tz(val, finalTimezone) : undefined}
			open={open}
			hasError={!!error}
			errorMessage={error?.message}
			inverted={inverted}
			icon={icon}
			shortTime={shortTime}
			placeholder={placeholder}
			hasIcon={!withLargeTitle}
			rafFormPrimary={withLargeTitle ? 'Departure' : undefined}
		/>
	</>
);

const ControllerRenderForRoundTrip = ({
	withLargeTitle,
	val,
	finalTimezone,
	open,
	error,
	inverted,
	icon,
	shortTime,
	finalDestinationTimezone,
	returnPlaceholder,
}) => (
	<>
		{withLargeTitle &&
			(val ? (
				<StyledH3 data-testid="daypicker-date" isReturn primary>
					{dayjs.tz(val, finalDestinationTimezone).format('MMM DD')}
				</StyledH3>
			) : (
				<SpaceFiller />
			))}
		<DateTimeInput
			value={val ? dayjs.tz(val, finalTimezone) : undefined}
			open={open}
			hasError={!!error}
			errorMessage={error?.message}
			inverted={inverted}
			icon={icon}
			shortTime={shortTime}
			placeholder={returnPlaceholder}
			hasIcon={!withLargeTitle}
			rafFormPrimary={withLargeTitle ? 'Return' : undefined}
		/>
	</>
);
export default DateTimePicker;
