import { scroller } from 'react-scroll';

export const scrollToRaf = () => {
	scroller.scrollTo('raf', {
		duration: 1500,
		delay: 100,
		smooth: true,
		offset: -150,
	});
};
