import { FC } from 'react';

interface FieldErrorProps {
	error: string | { key: string; values: { [key: string]: any } } | any;
}

export const FieldError: FC<FieldErrorProps> = ({ error }) => {
	if (typeof error === 'string') {
		return error;
	}

	return error?.key;
};
